import React, { Component } from "react";
class Convert extends Component {
  state = {
    TtoC: false,
    CtoT: true,
    convert: "morse",
    text: null,
    answer: null,
  };

  textToNum = (e) => {
    function convert(v) {
      switch (v) {
        case "a":
          return "1";
        case "b":
          return "2";
        case "c":
          return "3";
        case "d":
          return "4";
        case "e":
          return "5";
        case "f":
          return "6";
        case "g":
          return "7";
        case "h":
          return "8";
        case "i":
          return "9";
        case "j":
          return "10";
        case "k":
          return "11";
        case "l":
          return "12";
        case "m":
          return "13";
        case "n":
          return "14";
        case "o":
          return "15";
        case "p":
          return "16";
        case "q":
          return "17";
        case "r":
          return "18";
        case "s":
          return "19";
        case "t":
          return "20";
        case "u":
          return "21";
        case "v":
          return "22";
        case "w":
          return "23";
        case "x":
          return "24";
        case "y":
          return "25";
        case "z":
          return "26";
        case " ":
          return "";
        default:
          return "notvalid";
      }
    }
    var text = e.toLowerCase().split("");
    console.log(text);
    let tt = "";
    for (let i = 0; i < text.length; i++) {
      let letter = text[i];
      let letterNum = convert(letter);
      if (letterNum === "notvalid") {
        tt = "Just enter A-Z charachters";
        i = text.length + 1;
      } else {
        tt = tt.concat(" ").concat(letterNum);
      }
    }
    return tt;
  };
  numToText = (e) => {
    function convert(v) {
      // eslint-disable-next-line
      switch (v) {
        case "1":
          return "a";
        case "2":
          return "b";
        case "3":
          return "c";
        case "4":
          return "d";
        case "5":
          return "e";
        case "6":
          return "f";
        case "7":
          return "g";
        case "8":
          return "h";
        case "9":
          return "i";
        case "10":
          return "j";
        case "11":
          return "k";
        case "12":
          return "l";
        case "13":
          return "m";
        case "14":
          return "n";
        case "15":
          return "o";
        case "16":
          return "p";
        case "17":
          return "q";
        case "18":
          return "r";
        case "19":
          return "s";
        case "20":
          return "t";
        case "21":
          return "u";
        case "22":
          return "v";
        case "23":
          return "w";
        case "24":
          return "x";
        case "25":
          return "y";
        case "26":
          return "z";
        case " ":
          return " ";
        case "":
          return " ";
      }
    }
    var text = e.split(" ");
    console.log(text);
    let tt = "";
    for (let i = 0; i < text.length; i++) {
      let letter = text[i];
      if (parseInt(letter) >= 27 || parseInt(letter) <= 0) {
        tt = "Numbers must be between 1 and 26 !";
        i = text.length + 1;
      } else {
        let letterNum = convert(letter);
        if (tt === "") {
          tt = tt.concat("").concat(letterNum);
        } else {
          tt = tt.concat("").concat(letterNum);
        }
      }
    }
    return tt;
  };
  morseToText = (e) => {
    function convert(v) {
      switch (v) {
        case ".-":
          return "a";
        case "-...":
          return "b";
        case "-.-.":
          return "c";
        case "-..":
          return "d";
        case ".":
          return "e";
        case "..-.":
          return "f";
        case "--.":
          return "g";
        case "....":
          return "h";
        case "..":
          return "i";
        case ".---":
          return "j";
        case "-.-":
          return "k";
        case ".-..":
          return "l";
        case "--":
          return "m";
        case "-.":
          return "n";
        case "---":
          return "o";
        case ".--.":
          return "p";
        case "--.-":
          return "q";
        case ".-.":
          return "r";
        case "...":
          return "s";
        case "-":
          return "t";
        case "..-":
          return "u";
        case "...-":
          return "v";
        case ".--":
          return "w";
        case "-..-":
          return "x";
        case "-.--":
          return "y";
        case "--..":
          return "z";
        case ".----":
          return "1";
        case "..---":
          return "2";
        case "...--":
          return "3";
        case "....-":
          return "4";
        case ".....":
          return "5";
        case "-....":
          return "6";
        case "--...":
          return "7";
        case "---..":
          return "8";
        case "----.":
          return "9";
        case "-----":
          return "0";
        case "/":
          return " ";
        default:
          return "notvalid";
      }
    }
    var text = e.split(" ");
    let tt = "";
    for (let i = 0; i < text.length; i++) {
      let letter = text[i];
      let letterNum = convert(letter);
      if (letterNum === "notvalid") {
        tt = "One of morse code is not valid !";
        i = text.length + 1;
      } else {
        tt = tt.concat("").concat(letterNum);
      }
    }
    return tt;
  };
  textToMorse = (e) => {
    function convert(v) {
      switch (v) {
        case "a":
          return ".-";
        case "b":
          return "-...";
        case "c":
          return "-.-.";
        case "d":
          return "-..";
        case "e":
          return ".";
        case "f":
          return "..-.";
        case "g":
          return "--.";
        case "h":
          return "....";
        case "i":
          return "..";
        case "j":
          return ".---";
        case "k":
          return "-.-";
        case "l":
          return ".-..";
        case "m":
          return "--";
        case "n":
          return "-.";
        case "o":
          return "---";
        case "p":
          return ".--.";
        case "q":
          return "--.-";
        case "r":
          return ".-.";
        case "s":
          return "...";
        case "t":
          return "-";
        case "u":
          return "..-";
        case "v":
          return "...-";
        case "w":
          return ".--";
        case "x":
          return "-..-";
        case "y":
          return "-.--";
        case "z":
          return "--..";
        case "1":
          return ".----";
        case "2":
          return "..---";
        case "3":
          return "...--";
        case "4":
          return "....-";
        case "5":
          return ".....";
        case "6":
          return "-....";
        case "7":
          return "--...";
        case "8":
          return "---..";
        case "9":
          return "----.";
        case "0":
          return "-----";
        case " ":
          return "/";
        default:
          return "notvalid";
      }
    }
    var text = e.toLowerCase().split("");
    console.log(text);
    let tt = "";
    for (let i = 0; i < text.length; i++) {
      let letter = text[i];
      let letterNum = convert(letter);
      if (letterNum === "notvalid") {
        tt = "Text is not valid !";
        i = text.length + 1;
      } else {
        if (tt === "") {
          tt = tt.concat("").concat(letterNum);
        } else {
          tt = tt.concat(" ").concat(letterNum);
        }
      }
    }
    return tt;
  };
  checkBinaryString = (str) => {
    // Check if string is empty
    if (str === "") {
      return false;
    }
    // Loop through string and check each character
    for (var i = 0; i < str.length; i++) {
      if (
        !(
          str.charAt(i) === "0" ||
          str.charAt(i) === "1" ||
          str.charAt(i) === " "
        )
      ) {
        return false;
      }
    }
    return true;
  };
  binaryToText = (e) => {
    if (this.checkBinaryString(e)) {
      return e
        .split(/\s/g)
        .map((x) => (x = String.fromCharCode(parseInt(x, 2))))
        .join("");
    } else {
      return "It is not a binary code";
    }
  };
  textToBinary = (e) => {
    return e
      .split("")
      .map(function (char) {
        var b = char.charCodeAt(0).toString(2);

        var y = 8 - b.length;

        for (var i = 0; i < y; i++) {
          b = 0 + b;
        }

        return b;
      })
      .join(" ");
  };
  handleType = (e) => {
    // eslint-disable-next-line
    const { name, value } = e.target;
    if (value === "CtoT") {
      this.setState({ CtoT: true, TtoC: false });
    }
    if (value === "TtoC") {
      this.setState({ CtoT: false, TtoC: true });
    }
  };
  setInputConvert = (e) => {
    this.setState({ answer: null });
    // eslint-disable-next-line
    const { name, value } = e.target;
    this.setState({ convert: value });
  };
  setInput = (e) => {
    // eslint-disable-next-line
    const { name, value } = e.target;
    this.setState({ text: value });
  };
  handleConvert = () => {
    if (this.state.convert === "morse") {
      if (this.state.CtoT) {
        this.setState({ answer: this.morseToText(this.state.text) });
        if (this.props.isMember) {
        } else {
        }
      }
      if (this.state.TtoC) {
        this.setState({ answer: this.textToMorse(this.state.text) });
        if (this.props.isMember) {
        } else {
        }
      }
    }
    if (this.state.convert === "alphabet") {
      if (this.state.CtoT) {
        this.setState({ answer: this.numToText(this.state.text) });
        if (this.props.isMember) {
        } else {
        }
      }
      if (this.state.TtoC) {
        this.setState({ answer: this.textToNum(this.state.text) });
        if (this.props.isMember) {
        } else {
        }
      }
    }
    if (this.state.convert === "binary") {
      if (this.state.CtoT) {
        this.setState({ answer: this.binaryToText(this.state.text) });
        if (this.props.isMember) {
        } else {
        }
      }
      if (this.state.TtoC) {
        this.setState({ answer: this.textToBinary(this.state.text) });
        if (this.props.isMember) {
        } else {
        }
      }
    }
  };

  render() {
    return (
      <>
        {" "}
        <div className="min-vh-100 work d-flex justify-content-center align-self-center">
          {" "}
          <div className="min-h-work d-flex justify-content-center align-self-center w-100">
            {" "}
            <div className="row m-3 justify-content-evenly align-self-center w-100">
              {" "}
              <div className="col-12 mt-3 h-400 div-work d-flex justify-content-center max-w-992">
                {" "}
                <div className="row w-100 p-0">
                  {" "}
                  <div className="col-12 fs-3 fw-bolder text-center">
                    {" "}
                    TRANSLATOR{" "}
                  </div>{" "}
                  <div className="col-12">
                    {" "}
                    <select
                      id="convert"
                      className="form-select form-select-sm ms-2 bg-dark text-muted border-success"
                      aria-label="Default select example"
                      onChange={this.setInputConvert}
                      name="convert"
                      defaultValue="morse"
                    >
                      {" "}
                      <option value="morse">MORSE</option>{" "}
                      <option value="alphabet">ALPHABET</option>{" "}
                      <option value="binary">BINARY</option>{" "}
                    </select>{" "}
                  </div>{" "}
                  <div className="col-12">
                    {" "}
                    <input
                      onChange={this.setInput}
                      name="text"
                      className="form-control form-control-lg w-100 border-success bg-dark text-white"
                      type="text"
                      placeholder="Enter..."
                    />{" "}
                  </div>{" "}
                  <div className="col-12 d-flex flex-column flex-md-row ">
                    {" "}
                    <div class="form-check ms-5">
                      {" "}
                      <input
                        onChange={this.handleType}
                        class="form-check-input"
                        type="radio"
                        name="type"
                        id="CtoT"
                        value="CtoT"
                        checked={this.state.CtoT}
                      />{" "}
                      <label class="form-check-label" for="CtoT">
                        {" "}
                        {this.state.convert === "morse"
                          ? "MORSE CODE TO LETTERS"
                          : null}{" "}
                        {this.state.convert === "alphabet"
                          ? "ALPHABET CODE TO LETTERS"
                          : null}{" "}
                        {this.state.convert === "binary"
                          ? "BINARY CODE TO LETTERS"
                          : null}{" "}
                      </label>{" "}
                    </div>{" "}
                    <div class="form-check ms-5">
                      {" "}
                      <input
                        onChange={this.handleType}
                        class="form-check-input"
                        type="radio"
                        name="type"
                        id="TtoC"
                        value="TtoC"
                        checked={this.state.TtoC}
                      />{" "}
                      <label class="form-check-label" for="TtoC">
                        {" "}
                        {this.state.convert === "morse"
                          ? "LETTERS TO MORSE CODE"
                          : null}{" "}
                        {this.state.convert === "alphabet"
                          ? "LETTERS TO ALPHABET CODE"
                          : null}{" "}
                        {this.state.convert === "binary"
                          ? "LETTERS TO BINARY CODE"
                          : null}
                      </label>{" "}
                    </div>{" "}
                  </div>{" "}
                  <div
                    id="a"
                    className={
                      this.state.answer === null ? "col-12 d-none" : "col-12"
                    }
                  >
                    {" "}
                    <div class="input-group mb-3">
                      {" "}
                      <span
                        onClick={() => {
                          navigator.clipboard.writeText(this.state.answer);
                        }}
                        class="input-group-text bg-dark text-white c-pointer text-center d-flex flex-column"
                        id="basic-addon1"
                      >
                        {" "}
                        <i class="bi bi-clipboard"></i>{" "}
                        <span className="text-muted fs-8"> COPY</span>{" "}
                      </span>{" "}
                      <input
                        id="answer"
                        type="answer"
                        class="form-control bg-dark text-white"
                        placeholder=""
                        aria-label="Username"
                        value={this.state.answer}
                        aria-describedby="basic-addon1"
                      />{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="col-12 text-center">
                    {" "}
                    <button
                      onClick={this.handleConvert}
                      className="btn btn-success"
                    >
                      {" "}
                      CONVERT{" "}
                    </button>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </>
    );
  }
}
export default Convert;
