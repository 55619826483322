import "./loadingStyle.css";
import "./App.css";
import React, { useState } from "react";

import Splash from "./components/Splash";
import Convert from "./components/Convert";
import backdround from "./images/background/back.gif";
function App() {
  const [spalsh, setSplash] = useState(true);
  const endSplash = () => {
    setSplash(false);
    document.body.style.backgroundImage = ` url('${backdround}')`;
  };

  return (
    <div className="App">
      {spalsh ? (
        <Splash onEnd={endSplash} />
      ) : (
        <>
          <div className="container-fluid min-vh-100 p-0 m-0">
            <Convert user={null} isMember={false} />
          </div>
        </>
      )}
    </div>
  );
}

export default App;
